import React, { Component } from 'react';
import moment from 'moment';
import classNames from "classnames";
import bowser from "bowser";
import momentZone from "moment-timezone";
import { Editor } from "react-draft-wysiwyg";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import * as appActions from '../../reducers/app';
import strmzWideDefault from '../../resources/images/strmzio-logo.png';
import fanoutClient from '../../utils/FanoutClient';
import firebaseClient from '../../utils/FirebaseClient';
import {
    getTextDuration,
    withRouter,
    isMobile,
    defaultColor,
    getParticipantsTerms,
    setParticipantsTerms,
    getQueryVariable,
    checkHost,
    checkModerator,
    checkGuestSpeaker,
    getUserImg,
    getReconnectToken
} from '../../utils/HelperFunctions';
import Loader from '../Widget/Loader';
import Banner from '../Widget/Banner';
import VerifyCode from './VerifyCode';
import { Defines } from '../../utils/FanoutDefines';
import config from '../../config';

class UserInfoForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            title: '',
            company: '',
            email: '',
            verifyCode: false,
            verifyEmail: false,
            verifying: false,
            processing: false,
            validDomain: true,
            register: true,
            confirm: false,
            terms: false,
            showTerms: false,
            emailVerificationCode: null
        }

        this.handleJoin = this.handleJoin.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleVerifyEmail = this.handleVerifyEmail.bind(this);
        this.verifyEmail = this.verifyEmail.bind(this);
        this.validateEmail = this.validateEmail.bind(this);
        this.confirmJoiningAudience = this.confirmJoiningAudience.bind(this);
        this.handleVerifyLinkResponse = this.handleVerifyLinkResponse.bind(this);

        fanoutClient.on('verifyEmail', this.handleVerifyEmail);
        fanoutClient.on('verifyLinkResponse', this.handleVerifyLinkResponse);
    }

    checkJoin() {
        const { eventItem, role } = this.props;
        let addTime = moment().add(30, 'minutes').valueOf();

        if (this.joinTimeout) {
            clearTimeout(this.joinTimeout);
        }

        if ((role && role !== 'audience') || (role && role === 'audience' && eventItem && eventItem.startDate && eventItem.startDate < addTime)) {
            this.setState({
                register: false
            }, () => {
                this.checkTerms();
            });
        } else {
            this.joinTimeout = setTimeout(() => {
                this.checkJoin();
            }, 15000);
        }
    }

    isEntryGranted() {
        const { email } = this.state;
        const { eventItem, approvedEntry } = this.props;

        let oneHourTime = moment().add(1, 'hour').valueOf(), retVal = true;

        if ((eventItem.id && (!approvedEntry || (approvedEntry && approvedEntry !== eventItem.id))) && ((checkHost(email, eventItem) || checkModerator(email, eventItem) || checkGuestSpeaker(email, eventItem)) && eventItem && eventItem.startDate && eventItem.startDate > oneHourTime)) {
            retVal = false;
        }

        return retVal;
    }

    isRegisterText() {
        const { email } = this.state;
        const { eventItem, approvedEntry } = this.props;

        let retVal = false;

        if (getReconnectToken({ eventId: eventItem?.id, email }) && (eventItem.id && ((!approvedEntry) || (approvedEntry && approvedEntry !== eventItem.id))) && !((checkHost(email, eventItem) || checkModerator(email, eventItem) || checkGuestSpeaker(email, eventItem)))) {
            retVal = true;
        }

        return retVal;
    }

    setUserData() {
        const { user } = this.props;

        const username = localStorage.getItem('username');
        let userInfoData = localStorage.getItem('userInfo');

        if (userInfoData)
            userInfoData = JSON.parse(userInfoData);

        if ((username || (userInfoData && Object.keys(userInfoData).length)) || user) {
            this.setState({
                username: user && user.username ? user.username : userInfoData && userInfoData.username ? userInfoData.username : username ? username : '',
                title: user && user.title ? user.title : userInfoData && userInfoData.title ? userInfoData.title : '',
                company: user && user.companyName ? user.companyName : userInfoData && userInfoData.company ? userInfoData.company : '',
                email: user && user.email ? user.email : userInfoData && userInfoData.email ? userInfoData.email : ''
            }, () => {
                this.saveToLocalStorage();

                const { verifying, email } = this.state;

                if (verifying && user && user.email && email && user.email === email) {
                    this.setState({ verifying: false });
                }
            });
        }
    }

    checkRecToken(email) {
        const { eventItem } = this.props;

        let retVal = getReconnectToken({ eventId: eventItem?.id, email });

        return retVal;
    }

    componentDidMount() {
        const { email } = this.state;
        const { role, eventItem, user, userLoaded, showSignIn, signIn, params } = this.props;

        this.setUserData();

        if (eventItem && eventItem.audienceAuthenticate && role && role === 'audience' && !user && userLoaded && showSignIn && !signIn) {
            showSignIn(true);
        } else if (((eventItem && !eventItem.audienceAuthenticate && role && role === 'audience') || (role && role !== 'audience')) && (((!user) || (user && user.email !== email)) && !checkGuestSpeaker(email, eventItem))) {
            this.setState({
                verifying: true
            });
        }

        if (user && user.email) {
            this.setState({
                email: user.email
            });
        }

        if (email && role && role === 'audience') {
            this.checkMailDomain();
        }

        if (eventItem) {
            this.checkJoin();
        }

        if (params && params.linkId && (typeof params.linkId === 'string' || params.linkId instanceof String)) {
            const storageTerms = getParticipantsTerms(params.linkId);
            this.setState({ terms: storageTerms });
            this.setState({ showTerms: !storageTerms });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { email, username, title, company, processing, validDomain, terms } = this.state;
        const { role, eventItem, user, userLoaded, signIn, showSignIn } = this.props;

        if (user && (prevProps.userLoaded === userLoaded) && (prevProps.user !== user) && user.email && email && (checkHost(user.email, eventItem) || checkModerator(user.email, eventItem)) && !(!this.isEntryGranted() || (bowser && bowser.ios && bowser.chrome) || (!terms) || (!role) || (!validDomain) || (processing) || ((!username) || (username && username.length < 2))
            || ((!title) || (title && title.length < 2))
            || ((!company) || (company && company.length < 2))
            || ((!email) || (email && !this.validateEmail(email))))) {
            this.handleJoin(username, null, true, role);
        }

        if ((prevProps.role !== role) || (prevProps.eventItem !== eventItem) || (prevProps.user !== user)) {
            if (eventItem && eventItem.audienceAuthenticate && role && role === 'audience' && !user && userLoaded && showSignIn && !signIn) {
                showSignIn(true);
            } else if (((eventItem && !eventItem.audienceAuthenticate && role && role === 'audience') || (role && role !== 'audience')) && (((!user) || (user && user.email !== email)) && !checkGuestSpeaker(email, eventItem))) {
                this.setState({
                    verifying: true
                }, () => {
                    this.checkTerms();
                });
            }
        }

        if (role && role === 'audience' && eventItem && email !== prevState.email) {
            if (!eventItem.audienceAuthenticate && (user && user.email !== email) && !checkGuestSpeaker(email, eventItem)) {
                this.setState({
                    verifying: true
                }, () => {
                    this.checkTerms();
                });
            } else if (checkHost(email, eventItem) || checkModerator(email, eventItem) || checkGuestSpeaker(email, eventItem)) {
                this.setState({
                    verifying: false
                }, () => {
                    this.checkTerms();
                });
            }
        }

        if (role && role !== 'audience' && email !== prevState.email) {
            if ((!user || (user && user.email !== email)) && !checkGuestSpeaker(email, eventItem)) {
                this.setState({
                    verifying: true
                }, () => {
                    this.checkTerms();
                });
            } else if (checkHost(email, eventItem) || checkModerator(email, eventItem) || checkGuestSpeaker(email, eventItem)) {
                this.setState({
                    verifying: false
                }, () => {
                    this.checkTerms();
                });
            }
        }

        if (prevProps.user !== user) {
            if (user) {
                this.setUserData();
                this.checkTerms();
            }
        }

        if (email && ((email !== prevState.email) || (prevProps.role !== role) || (prevProps.eventItem !== eventItem)) && role && role === 'audience') {
            this.checkMailDomain();
        }

        if (eventItem && prevProps.eventItem !== eventItem) {
            this.checkJoin();
        }
    }

    checkTerms() {
        const { params } = this.props;

        if (params && params.linkId && (typeof params.linkId === 'string' || params.linkId instanceof String)) {
            let termsVal = getParticipantsTerms(params.linkId);
            this.setState({
                terms: termsVal,
                showTerms: !termsVal
            });
        }
    }

    componentWillUnmount() {
        fanoutClient.removeListener('verifyEmail', this.handleVerifyEmail);
        fanoutClient.removeListener('verifyLinkResponse', this.handleVerifyLinkResponse);
    }

    handleVerifyLinkResponse(data) {
        const { username, title, company, email } = this.state;
        const { registerUser, location } = this.props;

        try {
            console.log("UserInfoForm-handleVerifyLinkResponse response received from server", data);
            let isVerify = false, verificationCode = getQueryVariable("code"), verificationEmail = getQueryVariable("email");

            try {
                if (verificationCode && (typeof verificationCode === 'string' || verificationCode instanceof String) && verificationCode.length && verificationCode.length > 6) {
                    verificationCode = verificationCode.substring(0, 6);
                }
            } catch (e) {
                console.error('verificationCode error', e)
            }

            if (location && location.pathname) {
                isVerify = location.pathname.indexOf("/verify/") !== -1;

                if (isVerify && !verificationCode) {
                    this.setState({
                        verifyEmail: false,
                        verifyCode: true
                    });
                } else if (isVerify && verificationCode && verificationEmail) {
                    if (data.event && data.event.eventId) {
                        const trimName = username.trim(), trimTitle = title.trim(), trimCompany = company.trim(), trimEmail = email.trim();

                        if (trimName && trimTitle && trimCompany && trimEmail && trimEmail === verificationEmail) {
                            registerUser(trimName, verificationCode, trimTitle, trimCompany, trimEmail);
                            this.setState({
                                verifyCode: false,
                                verifyEmail: false,
                                verifying: false,
                                processing: false,
                                validDomain: true,
                                emailVerificationCode: verificationCode
                            });
                        }
                    }
                }
            }
        }
        catch (error) {
            console.error("UserInfoForm-Failed verifyLinkResponse", error, data);
        }
    }

    handleVerifyEmail(data) {
        if (data && data.status && data.status === Defines.Response.OK) {
            this.setState({
                verifyEmail: false,
                verifyCode: true,
                confirm: false
            }, () => {
                const { showConfirm, hideConfirm } = this.props;

                if (showConfirm && hideConfirm) {
                    hideConfirm();
                }
            });
        } else if (data && data.status && data.status === Defines.Response.NotAcceptable) {
            this.setState({
                confirm: true
            });
        } else if (data && data.status && data.status === Defines.Response.Forbidden) {
            const { user, userLoaded, showSignIn, signIn } = this.props;

            if (!user && userLoaded && showSignIn && !signIn) {
                showSignIn(true);
            }
        } else {
            //TODO add show error
            console.error('verifyEmail response=false');
        }
    }

    handleJoin(username, code = null, auth = true, role = null) {
        const { title, company, email, register, emailVerificationCode } = this.state;
        const { joinUser, user, registerUser } = this.props;

        let verificationCode = code ? code : emailVerificationCode;

        const trimName = username.trim(), trimTitle = title.trim(), trimCompany = company.trim(), trimEmail = email.trim();

        if (joinUser && trimName && trimTitle && trimCompany && trimEmail) {
            this.setState({ processing: true });

            if (user && user.uid) {
                let updateData = {}

                if (!user.username || (user.username && user.username !== trimName)) {
                    updateData.username = trimName;
                    updateData.username_lc = trimName.toLowerCase();
                }

                if (!user.title || (user.title && user.title !== trimTitle)) {
                    updateData.title = trimTitle;
                }

                if (!user.companyName || (user.companyName && user.companyName !== trimCompany)) {
                    updateData.companyName = trimCompany;
                }

                if (updateData && Object.keys(updateData).length) {
                    firebaseClient.updateUser(user.uid, updateData).then(() => {
                        if (register && registerUser) {
                            registerUser(trimName, verificationCode, trimTitle, trimCompany, trimEmail);
                            this.setState({
                                verifyCode: false,
                                verifyEmail: false,
                                verifying: false,
                                processing: false,
                                validDomain: true,
                                register: true
                            });
                        } else {
                            joinUser(auth, trimName, verificationCode, trimTitle, trimCompany, getUserImg(user), trimEmail, user && user.email && user.email !== email ? true : false, role);
                        }
                    });
                } else {
                    if (register && registerUser) {
                        registerUser(trimName, verificationCode, trimTitle, trimCompany, trimEmail);
                        this.setState({
                            verifyCode: false,
                            verifyEmail: false,
                            verifying: false,
                            processing: false,
                            validDomain: true,
                            register: true
                        });
                    } else {
                        joinUser(auth, trimName, verificationCode, trimTitle, trimCompany, getUserImg(user), trimEmail, user && user.email && user.email !== email ? true : false, role);
                    }
                }
            } else {
                if (register && registerUser) {
                    registerUser(trimName, verificationCode, trimTitle, trimCompany, trimEmail);
                    this.setState({
                        verifyCode: false,
                        verifyEmail: false,
                        verifying: false,
                        processing: false,
                        validDomain: true,
                        register: true
                    });
                } else {
                    joinUser(auth, trimName, verificationCode, trimTitle, trimCompany, getUserImg(user), trimEmail, user && user.email && user.email !== email ? true : false, role);
                }
            }
        }

    }

    async verifyEmail(email) {
        const { username } = this.state;
        const { role, eventItem } = this.props;

        if (email && this.validateEmail(email)) {
            let reconnected = await fanoutClient.reconnectAsMe(email, eventItem.id);

            if (!reconnected) {
                await fanoutClient.verifyEmail(email.toLowerCase(), role);
            } else {
                await this.handleJoin(username, null, false, role);
            }
            return true;
        } else {
            return false;
        }
    }

    handleKeyDown(event) {
        const { username, title, company, email, verifying, validDomain, terms } = this.state;
        const { role } = this.props;

        if (!(bowser && bowser.ios && bowser.chrome) && event.key === 'Enter' && validDomain && terms && username && username.length > 1
            && title && title.length > 1 && company && company.length > 1
            && email && this.validateEmail(email)) {
            if (verifying) {
                this.verifyEmail(email);
            } else {
                this.handleJoin(username, null, true, role);
            }
        }
    }

    saveToLocalStorage() {
        const { username, title, company, email } = this.state;

        const storageItem = {
            username: username.trim(),
            title: title.trim(),
            company: company.trim(),
            email: email.trim()
        }

        localStorage.setItem('userInfo', JSON.stringify(storageItem));
    }

    validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    checkMailDomain() {
        const { email } = this.state;
        const { eventItem } = this.props;

        if (eventItem && eventItem.domains && eventItem.domains.length) {
            let emailDomainAndExtension = email.substring(email.lastIndexOf("@") + 1);

            const verifiedDomains = []

            eventItem.domains.forEach(function (domain) {
                const selectedDomain = domain.text.replaceAll('*', '');

                if (emailDomainAndExtension.includes(selectedDomain)) {
                    verifiedDomains.push(selectedDomain);
                }
            })

            if (verifiedDomains.length > 0) {
                this.setState({ validDomain: true })
            } else {
                this.setState({ validDomain: false })
            }
        }
    }

    confirmJoiningAudience() {
        const { email, username } = this.state;
        const { eventItem, showConfirm, hideConfirm } = this.props;

        if (!showConfirm) {
            let addTime = moment().add(30, 'minutes').valueOf();

            if (email && email.toLowerCase) {
                fanoutClient.verifyEmail(email.toLowerCase(), 'audience');
            }

            if (eventItem && eventItem.startDate && eventItem.startDate > addTime) {
                this.setState({
                    register: true
                });
            }
        } else {
            let addTime = moment().add(30, 'minutes').valueOf();

            if (eventItem && eventItem.startDate && eventItem.startDate < addTime) {
                this.setState({
                    confirm: false
                }, () => {
                    if (showConfirm && hideConfirm) {
                        hideConfirm();
                    }
                    this.handleJoin(username, null, true, 'audience');
                })
            } else {
                this.setState({
                    register: true,
                    confirm: false
                }, () => {
                    if (showConfirm && hideConfirm) {
                        hideConfirm();
                    }
                    window.scrollTo(0, 0);
                });
            }
        }
    }

    checkBranding(branding) {
        let retVal = false, brandingData = branding || {};

        brandingData.logo = null;
        brandingData = Object.values(brandingData);
        brandingData = brandingData.filter(item => item);

        if (brandingData && brandingData.length) {
            retVal = true;
        }

        return retVal;
    }

    handleSetTerms(value) {
        const { params, eventItem } = this.props;

        if (params && params.linkId && (typeof params.linkId === 'string' || params.linkId instanceof String)) {
            if (params.linkId && eventItem && eventItem.startDate) {
                this.setState({ terms: value });
                setParticipantsTerms(params.linkId, value, eventItem.startDate);
            }
        }
    }


    render() {
        const { username, title, company, email, verifyCode, verifyEmail, verifying, processing, validDomain, register, confirm, terms, showTerms } = this.state;
        const { t, eventItem, role, showConfirm, hideConfirm, signIn, userLoaded } = this.props;

        return (
            <div>
                {eventItem ?
                    <div
                        className={classNames('lobby-wrapper', { 'hide': signIn || confirm || showConfirm || verifyCode })}
                        style={eventItem && eventItem.branding && eventItem.branding.colorSchemeEventPage && eventItem.branding.colorSchemeEventPage.bg ?
                            {
                                background: eventItem.branding.colorSchemeEventPage.bg ? `rgba(${eventItem.branding.colorSchemeEventPage.bg.r}, ${eventItem.branding.colorSchemeEventPage.bg.g}, ${eventItem.branding.colorSchemeEventPage.bg.b}, ${eventItem.branding.colorSchemeEventPage.bg.a})` : defaultColor.eventPage.bg
                            }
                            :
                            {}
                        }
                    >
                        {eventItem.branding ?
                            isMobile() ?
                                eventItem.branding.narrowHeaderBanner ?
                                    <img
                                        src={eventItem.branding.narrowHeaderBanner}
                                        className='branding-header'
                                        alt='branding-header'
                                        loading='lazy'
                                    />
                                    : null
                                : eventItem.branding.wideHeaderBanner ?
                                    <img
                                        src={eventItem.branding.wideHeaderBanner}
                                        className='branding-header'
                                        alt='branding-header'
                                        loading='lazy'
                                    />
                                    : null
                            : null
                        }
                        <div
                            className={classNames('lobby-header', { 'no-branding': (!eventItem) || (eventItem && !this.checkBranding(eventItem.branding)) })}
                            style={eventItem && eventItem.branding && eventItem.branding.colorSchemeEventTitle && (eventItem.branding.colorSchemeEventTitle.color || eventItem.branding.colorSchemeEventTitle.bg) ?
                                {
                                    color: eventItem.branding.colorSchemeEventTitle.color ? `rgba(${eventItem.branding.colorSchemeEventTitle.color.r}, ${eventItem.branding.colorSchemeEventTitle.color.g}, ${eventItem.branding.colorSchemeEventTitle.color.b}, ${eventItem.branding.colorSchemeEventTitle.color.a})` : defaultColor.eventTitle.color,
                                    background: eventItem.branding.colorSchemeEventTitle.bg ? `rgba(${eventItem.branding.colorSchemeEventTitle.bg.r}, ${eventItem.branding.colorSchemeEventTitle.bg.g}, ${eventItem.branding.colorSchemeEventTitle.bg.b}, ${eventItem.branding.colorSchemeEventTitle.bg.a})` : defaultColor.eventTitle.bg
                                }
                                :
                                {}
                            }
                        >
                            {eventItem ?
                                <div className='event-info'>
                                    <img src={eventItem.branding && eventItem.branding.corporateLogo ? eventItem.branding.corporateLogo : strmzWideDefault} alt='company' loading='lazy' />
                                    <div className='event-title'>
                                        {eventItem.title ? <p className='title'>{eventItem.title}</p> : null}
                                        {eventItem.eventSubtitle ? <p className='sub-title'>{eventItem.eventSubtitle}</p> : null}
                                    </div>
                                </div>
                                :
                                null
                            }
                        </div>
                        <div className='lobby-body'>
                            <div
                                className='body-event-details'
                                style={eventItem && eventItem.branding && eventItem.branding.colorSchemeEventDetails && (eventItem.branding.colorSchemeEventDetails.color || eventItem.branding.colorSchemeEventDetails.bg) ?
                                    {
                                        color: eventItem.branding.colorSchemeEventDetails.color ? `rgba(${eventItem.branding.colorSchemeEventDetails.color.r}, ${eventItem.branding.colorSchemeEventDetails.color.g}, ${eventItem.branding.colorSchemeEventDetails.color.b}, ${eventItem.branding.colorSchemeEventDetails.color.a})` : defaultColor.eventDetails.color,
                                        background: eventItem.branding.colorSchemeEventDetails.bg ? `rgba(${eventItem.branding.colorSchemeEventDetails.bg.r}, ${eventItem.branding.colorSchemeEventDetails.bg.g}, ${eventItem.branding.colorSchemeEventDetails.bg.b}, ${eventItem.branding.colorSchemeEventDetails.bg.a})` : defaultColor.eventDetails.bg
                                    }
                                    :
                                    {}
                                }
                            >
                                <p className='info-title'>{t('event_details')}</p>
                                {eventItem.description && ((typeof eventItem.description !== 'string' && eventItem.descriptionText) || typeof eventItem.description === 'string') ?
                                    <div className='details-line'>
                                        <p>{t('event_description')}:</p>
                                        <div className='value description'>
                                            {typeof eventItem.description !== 'string' ?
                                                (
                                                    <Editor
                                                        toolbarHidden
                                                        readOnly
                                                        initialContentState={eventItem.description}
                                                    />
                                                )
                                                : eventItem.description}
                                        </div>
                                    </div>
                                    : null
                                }
                                <div className='details-line'>
                                    <p>{t('date')}:</p>
                                    <p className='value'>{moment(new Date(eventItem.startDate ? eventItem.startDate : moment().unix())).format('L')}</p>
                                </div>
                                <div className='details-line'>
                                    <p>{t('time')}:</p>
                                    <p className='value'>{moment(new Date(eventItem.startDate ? eventItem.startDate : moment().unix())).format('HH:mm a')}, {moment.tz(momentZone.tz.guess()).zoneAbbr()}</p>
                                </div>
                                <div className='details-line'>
                                    <p>{t('duration')}:</p>
                                    <p className='value'>{`${getTextDuration(eventItem.duration, t('hour'), t('hours'))}`}</p>
                                </div>
                            </div>
                            {bowser && bowser.ios && bowser.chrome ?
                                <div
                                    className='body-user-details'
                                    style={eventItem && eventItem.branding && eventItem.branding.colorSchemeEventRegistration && (eventItem.branding.colorSchemeEventRegistration.color || eventItem.branding.colorSchemeEventRegistration.bg) ?
                                        {
                                            color: eventItem.branding.colorSchemeEventRegistration.color ? `rgba(${eventItem.branding.colorSchemeEventRegistration.color.r}, ${eventItem.branding.colorSchemeEventRegistration.color.g}, ${eventItem.branding.colorSchemeEventRegistration.color.b}, ${eventItem.branding.colorSchemeEventRegistration.color.a})` : defaultColor.eventRegistration.color,
                                            background: eventItem.branding.colorSchemeEventRegistration.bg ? `rgba(${eventItem.branding.colorSchemeEventRegistration.bg.r}, ${eventItem.branding.colorSchemeEventRegistration.bg.g}, ${eventItem.branding.colorSchemeEventRegistration.bg.b}, ${eventItem.branding.colorSchemeEventRegistration.bg.a})` : defaultColor.eventRegistration.bg
                                        }
                                        :
                                        {}
                                    }
                                >
                                    <p className='info-title'>{(!eventItem) || (eventItem && !this.checkBranding(eventItem.branding)) ? t('register_for_event') : t('event_registration')}</p>
                                    <Banner type="notSupportBanner">
                                        <div className='banner-texts'>
                                            <p className='banner-text bold-text'>{t('not_support_browser_title')}</p>
                                            <p className='banner-text sm-text'>{t('not_support_browser_text')}</p>
                                        </div>
                                    </Banner>
                                </div>
                                :
                                <div
                                    className='body-user-details'
                                    style={eventItem && eventItem.branding && eventItem.branding.colorSchemeEventRegistration && (eventItem.branding.colorSchemeEventRegistration.color || eventItem.branding.colorSchemeEventRegistration.bg) ?
                                        {
                                            color: eventItem.branding.colorSchemeEventRegistration.color ? `rgba(${eventItem.branding.colorSchemeEventRegistration.color.r}, ${eventItem.branding.colorSchemeEventRegistration.color.g}, ${eventItem.branding.colorSchemeEventRegistration.color.b}, ${eventItem.branding.colorSchemeEventRegistration.color.a})` : defaultColor.eventRegistration.color,
                                            background: eventItem.branding.colorSchemeEventRegistration.bg ? `rgba(${eventItem.branding.colorSchemeEventRegistration.bg.r}, ${eventItem.branding.colorSchemeEventRegistration.bg.g}, ${eventItem.branding.colorSchemeEventRegistration.bg.b}, ${eventItem.branding.colorSchemeEventRegistration.bg.a})` : defaultColor.eventRegistration.bg
                                        }
                                        :
                                        {}
                                    }
                                >
                                    <p className='info-title'>{(!eventItem) || (eventItem && !this.checkBranding(eventItem.branding)) ? t('register_for_event') : t('event_registration')}</p>
                                    {eventItem && this.checkBranding(eventItem.branding) ? <p className='info-text'>{t('event_text')}</p> : null}
                                    <div className='input-wrapper'>
                                        <p>
                                            {t('your_name')}
                                            <span className='asterisk'>*</span>
                                        </p>
                                        <input
                                            type='text'
                                            onChange={(e) => {
                                                this.setState({
                                                    username: e.target.value
                                                }, () => {
                                                    this.saveToLocalStorage();
                                                });
                                            }}
                                            onKeyDown={this.handleKeyDown}
                                            value={username}
                                            autoFocus
                                            disabled={processing}
                                        />
                                    </div>
                                    <div className='input-wrapper'>
                                        <p>
                                            {t('title')}
                                            <span className='asterisk'>*</span>
                                        </p>
                                        <input
                                            type='text'
                                            onChange={(e) => {
                                                this.setState({
                                                    title: e.target.value
                                                }, () => {
                                                    this.saveToLocalStorage();
                                                });
                                            }}
                                            onKeyDown={this.handleKeyDown}
                                            value={title}
                                            disabled={processing}
                                        />
                                    </div>
                                    <div className='input-wrapper'>
                                        <p>
                                            {t('company')}
                                            <span className='asterisk'>*</span>
                                        </p>
                                        <input
                                            type='text'
                                            id='organisation'
                                            onChange={(e) => {
                                                this.setState({
                                                    company: e.target.value
                                                }, () => {
                                                    this.saveToLocalStorage();
                                                });
                                            }}
                                            onKeyDown={this.handleKeyDown}
                                            value={company}
                                            disabled={processing}
                                        />
                                    </div>
                                    <div className='input-wrapper'>
                                        <p>
                                            {t('email')}
                                            <span className='asterisk'>*</span>
                                        </p>
                                        <input
                                            type='email'
                                            onChange={(e) => {
                                                this.setState({
                                                    email: e.target.value
                                                }, () => {
                                                    this.saveToLocalStorage();
                                                });
                                            }}
                                            onKeyDown={this.handleKeyDown}
                                            value={email}
                                            disabled={processing}
                                        />
                                        {!validDomain ? <p className='domain-text'>{t('verify_domain')}</p> : null}
                                    </div>
                                    {showTerms ?
                                        <div className='accept-terms'>
                                            <input
                                                type='checkbox'
                                                checked={terms}
                                                onChange={(e) => this.handleSetTerms(e.target.checked)}
                                            />
                                            <p>
                                                {t('agree_2')}
                                                <a
                                                    href={`https://${config.homeSiteDomain}/participant-terms-of-use`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {t('event_participant_terms_of_use')}
                                                </a>
                                                .
                                            </p>
                                        </div>
                                        : null
                                    }
                                    {!userLoaded ?
                                        <button
                                            className='btn btn-join'
                                            disabled={true}
                                            style={eventItem && eventItem.branding && eventItem.branding.colorSchemeEventRegistration && eventItem.branding.colorSchemeEventRegistration.btn ?
                                                {
                                                    background: eventItem.branding.colorSchemeEventRegistration.btn ? `rgba(${eventItem.branding.colorSchemeEventRegistration.btn.r}, ${eventItem.branding.colorSchemeEventRegistration.btn.g}, ${eventItem.branding.colorSchemeEventRegistration.btn.b}, ${eventItem.branding.colorSchemeEventRegistration.btn.a})` : defaultColor.eventRegistration.btn
                                                }
                                                :
                                                {}
                                            }
                                        >
                                            <div className="dots-loader">
                                                <div className="dot-loader dot1" />
                                                <div className="dot-loader dot2" />
                                                <div className="dot-loader dot3" />
                                            </div>
                                        </button>
                                        : verifying ?
                                            <button
                                                className='btn btn-join'
                                                disabled={!this.isEntryGranted() || (bowser && bowser.ios && bowser.chrome) || (!terms) || (!role) || (!validDomain) || (verifying && (
                                                    ((!username) || (username && username.length < 2))
                                                    || ((!title) || (title && title.length < 2))
                                                    || ((!company) || (company && company.length < 2))
                                                    || ((!email) || (email && !this.validateEmail(email)))
                                                ))}
                                                onClick={() => this.verifyEmail(email)}
                                                style={eventItem && eventItem.branding && eventItem.branding.colorSchemeEventRegistration && eventItem.branding.colorSchemeEventRegistration.btn ?
                                                    {
                                                        background: eventItem.branding.colorSchemeEventRegistration.btn ? `rgba(${eventItem.branding.colorSchemeEventRegistration.btn.r}, ${eventItem.branding.colorSchemeEventRegistration.btn.g}, ${eventItem.branding.colorSchemeEventRegistration.btn.b}, ${eventItem.branding.colorSchemeEventRegistration.btn.a})` : defaultColor.eventRegistration.btn
                                                    }
                                                    :
                                                    {}
                                                }
                                            >
                                                {register ? t('register') : (this.checkRecToken(email) || ((checkHost(email, eventItem) || checkModerator(email, eventItem) || checkGuestSpeaker(email, eventItem)))) ? t('join') : t('verify_email')}
                                            </button>
                                            :
                                            <button
                                                className='btn btn-join'
                                                disabled={!this.isEntryGranted() || (bowser && bowser.ios && bowser.chrome) || (!terms) || (!role) || (!validDomain) || (processing) || ((!username) || (username && username.length < 2))
                                                    || ((!title) || (title && title.length < 2))
                                                    || ((!company) || (company && company.length < 2))
                                                    || ((!email) || (email && !this.validateEmail(email)))
                                                }
                                                onClick={() => this.handleJoin(username, null, true, role)}
                                                style={eventItem && eventItem.branding && eventItem.branding.colorSchemeEventRegistration && eventItem.branding.colorSchemeEventRegistration.btn ?
                                                    {
                                                        background: eventItem.branding.colorSchemeEventRegistration.btn ? `rgba(${eventItem.branding.colorSchemeEventRegistration.btn.r}, ${eventItem.branding.colorSchemeEventRegistration.btn.g}, ${eventItem.branding.colorSchemeEventRegistration.btn.b}, ${eventItem.branding.colorSchemeEventRegistration.btn.a})` : null
                                                    }
                                                    :
                                                    {}
                                                }
                                            >
                                                {register ? t('register') : t('join')}
                                            </button>
                                    }
                                </div>
                            }
                        </div>
                        {eventItem.branding ?
                            isMobile() ?
                                eventItem.branding.narrowSupplementaryPicture ?
                                    <img
                                        src={eventItem.branding.narrowSupplementaryPicture}
                                        className='branding-supplementary'
                                        alt='branding-supplementary'
                                        loading='lazy'
                                    />
                                    : null
                                : eventItem.branding.wideSupplementaryPicture ?
                                    <img
                                        src={eventItem.branding.wideSupplementaryPicture}
                                        className='branding-supplementary'
                                        alt='branding-supplementary'
                                        loading='lazy'
                                    />
                                    : null
                            : null
                        }
                        {eventItem.branding ?
                            isMobile() ?
                                eventItem.branding.narrowFooterBanner ?
                                    <img
                                        src={eventItem.branding.narrowFooterBanner}
                                        className='branding-footer'
                                        alt='branding-footer'
                                        loading='lazy'
                                    />
                                    : null
                                : eventItem.branding.wideFooterBanner ?
                                    <img
                                        src={eventItem.branding.wideFooterBanner}
                                        className='branding-footer'
                                        alt='branding-footer'
                                        loading='lazy'
                                    />
                                    : null
                            : null
                        }
                    </div>
                    :
                    <Loader
                        text={t('loading')}
                        dots
                    />
                }
                {confirm || showConfirm ?
                    <div className='sign-in-wrapper'>
                        <div className='sign-in-div'>
                            <p className='title user-auth'>{t('confirm_text_1')}</p>
                            <p className='text confirm-text'>
                                {t('confirm_text_2')}
                                <span>{t('cancel')}</span>
                                {t('confirm_text_3')}
                            </p>
                            <button
                                className='btn btn-sign-in btn-blue'
                                onClick={this.confirmJoiningAudience}
                            >
                                <p>{t('yes')}</p>
                            </button>
                            <button
                                className='btn btn-sign-in'
                                onClick={() => this.setState({
                                    confirm: false,
                                    processing: false
                                }, () => {
                                    if (showConfirm && hideConfirm) {
                                        hideConfirm();
                                    }
                                    window.scrollTo(0, 0);
                                })
                                }
                            >
                                <p>{t('cancel')}</p>
                            </button>
                        </div>
                    </div>
                    : null}
                {verifyCode ?
                    <VerifyCode
                        eventItem={eventItem}
                        close={() => this.setState({ verifyCode: false })}
                        verifyEmail={verifyEmail}
                        email={email}
                        role={role}
                        register={register}
                    />
                    : null}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.firebase.user,
        userLoaded: state.firebase.userLoaded,
        signIn: state.app.signIn,
        approvedEntry: state.room.approvedEntry
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        showSignIn: (value) => {
            dispatch(appActions.showSignIn({ signIn: value }));
        }
    };
};

const UserInfoFormContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(UserInfoForm);

export default withTranslation()(withRouter(UserInfoFormContainer));