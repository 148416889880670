import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import deviceManager from '../../../../utils/DeviceManager';
import * as devicesActions from '../../../../reducers/device';
import * as roomActions from '../../../../reducers/room';
import CallManager from "../../../../utils/CallManager";
import AudioMixer from "../../../../utils/AudioMixer";

class ConferenceSettings extends Component {
    constructor(props) {
        super(props);
        // defaults
        this.state = {
            runningAnimation: false,
            attendeesSettingsOpened: props.attendeesSettingsOpened,
            noiseSuppression: false
        };

        this.handleChangeLowBandwidthMode = this.handleChangeLowBandwidthMode.bind(this);
        this.handleChangeNoiseSuppression = this.handleChangeNoiseSuppression.bind(this);
        this.handleChangeShowSelfView = this.handleChangeShowSelfView.bind(this);
        this.handleChangeAudioOutputDevice = this.handleChangeAudioOutputDevice.bind(this);
        this.handleChangeVideoInputDevice = this.handleChangeVideoInputDevice.bind(this);
        this.handleChangeAudioInputDevice = this.handleChangeAudioInputDevice.bind(this);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let stateUpdate = {
            attendeesSettingsOpened: nextProps.attendeesSettingsOpened,
        };
        // Check if it should run animation
        if (prevState.attendeesSettingsOpened === true && nextProps.attendeesSettingsOpened === false) {
            stateUpdate.runningAnimation = true;
        }

        return stateUpdate;
    }

    componentDidMount() {
        const { setLowBandwidthMode, videoEnabled } = this.props;

        if (setLowBandwidthMode) {
            setLowBandwidthMode(!videoEnabled);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { attendeesListOpened, attendeesSettingsOpened } = this.props;
        if (prevProps.attendeesListOpened === true && attendeesListOpened === false) {
            setTimeout(() => {
                this.setState({ runningAnimation: false });
            }, 250);
        }

        if (prevProps.attendeesSettingsOpened === true && attendeesSettingsOpened === false) {
            setTimeout(() => {
                this.setState({ runningAnimation: false });
            }, 250);
        }
    }

    handleChangeLowBandwidthMode(event) {
        const { setLowBandwidthMode, muteRemoteVideosForMe } = this.props;

        if (setLowBandwidthMode) {
            let targetValue = event.target.checked;

            setLowBandwidthMode(targetValue);

            if (muteRemoteVideosForMe) {
                muteRemoteVideosForMe(targetValue);
            }
        }
    }

    handleChangeNoiseSuppression(event) {
        this.setState({
            noiseSuppression: event.target.checked,
        });
    }

    handleChangeShowSelfView(event) {
        const { showSelfView } = this.props;

        if (showSelfView) {
            showSelfView(event.target.checked);
        }
    }

    renderCustomSettings() {
        const { customSettings } = this.props;

        if (customSettings) {
            return React.createElement(customSettings, { ...this.props });
        }
    }

    handleChangeAudioOutputDevice(event) {
        const {
            audioOutputDevice,
            audioOutputDevices,
            conferenceAlias: eventId,
        } = this.props;

        const deviceId = event.target.value;
        deviceManager.handleChangeAudioOutputDevice(event);

        let am = AudioMixer.get({ eventId });
        let videooutput = audioOutputDevices.find(d => d.deviceId === deviceId);
        console.log('handleChangeVideoInputDevice', eventId, videooutput);
        am.sinkId = deviceManager.getDefaultOutputAudioDeviceId(deviceId);
    }

    handleChangeVideoInputDevice(event) {
        const {
            videoInputDevice,
            videoInputDevices,
            conferenceAlias: eventId,
        } = this.props;

        const deviceId = event.target.value;
        deviceManager.handleChangeVideoInputDevice(event);

        let cm = CallManager.get({ eventId });
        let videoinput = videoInputDevices.find(d => d.deviceId === deviceId);
        console.log('handleChangeVideoInputDevice', eventId, videoinput);
        cm.replaceVideoInput(videoinput);
    }

    handleChangeAudioInputDevice(event) {
        const {
            audioInputDevice,
            audioInputDevices,
            conferenceAlias: eventId,
        } = this.props;

        const deviceId = event.target.value;
        deviceManager.handleChangeAudioInputDevice(event);

        let cm = CallManager.get({ eventId });
        let audioinput = audioInputDevices.find(d => d.deviceId === deviceId);
        console.log('handleChangeAudioInputDevice', eventId, audioinput);
        cm.replaceAudioInput(audioinput);
    }

    render() {
        const {
            noiseSuppression,
            runningAnimation
        } = this.state;

        const {
            attendeesSettingsOpened,
            isListener,
            audioInputDevice,
            audioInputDevices,
            audioOutputDevice,
            audioOutputDevices,
            videoInputDevice,
            videoInputDevices,
            selfView,
            lowBandwidthMode
        } = this.props;
        return (
            <div
                className={
                    runningAnimation
                        ? "attendees-settings attendees-settings-out"
                        : attendeesSettingsOpened
                            ? "attendees-settings"
                            : "attendees-settings-hidden"
                }
            >
                <div className="attendees-settings-header">
                    <h1>Settings</h1>
                </div>

                <div className="settings">
                    <div className="content">
                        <form>
                            <div className="attendees-settings-header">
                                <h1>Personal settings</h1>
                            </div>
                            <div className="form-group form-output">
                                <select
                                    name="output"
                                    value={audioOutputDevice && audioOutputDevice.deviceId ? audioOutputDevice.deviceId : ''}
                                    onChange={this.handleChangeAudioOutputDevice}
                                    className="form-control select-audio-output"
                                    disabled={false}
                                >
                                    {audioOutputDevices.map((device, i) => (
                                        <option key={i} value={device.deviceId}>
                                            {device.label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            {!isListener && (
                                <Fragment>
                                    <div className="form-group last">
                                        <select
                                            name="video"
                                            className="form-control select-video-device"
                                            value={videoInputDevice && videoInputDevice.deviceId ? videoInputDevice.deviceId : ''}
                                            onChange={this.handleChangeVideoInputDevice}
                                            disabled={false}
                                        >
                                            {videoInputDevices.map((device, i) => (
                                                <option key={i} value={device.deviceId}>
                                                    {device.label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <select
                                            name="audio"
                                            className="form-control select-audio-input"
                                            value={audioInputDevice && audioInputDevice.deviceId ? audioInputDevice.deviceId : ''}
                                            onChange={this.handleChangeAudioInputDevice}
                                            disabled={false}
                                        >
                                            {audioInputDevices.map((device, i) => (
                                                <option key={i} value={device.deviceId}>
                                                    {device.label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </Fragment>
                            )}
                            <div className="form-group switch-enable lowBandwidthMode">
                                <div className="switch-mode">
                                    <p className="lowBandwidthMode-text">
                                        If you are having internet bandwidth issues, enable
                                        <span>{` Low bandwidth mode `}</span>
                                        to stop downloading video from other participants - this won't impact anyone else and you'll still be able to hear everyone and see shared screens.
                                    </p>
                                    <input
                                        id="lowBandwidthMode"
                                        name="lowBandwidthMode"
                                        type="checkbox"
                                        onChange={this.handleChangeLowBandwidthMode}
                                        checked={lowBandwidthMode}
                                    />
                                    <label htmlFor="lowBandwidthMode">
                                        Low bandwidth mode
                                    </label>
                                </div>
                            </div>
                            <div className="form-group switch-enable noiseSuppression">
                                <div className="switch-mode">
                                    <input
                                        id="noiseSuppression"
                                        name="noiseSuppression"
                                        type="checkbox"
                                        onChange={this.handleChangeNoiseSuppression}
                                        checked={noiseSuppression}
                                    />
                                    <label htmlFor="noiseSuppression">
                                        Noise suppression
                                    </label>
                                </div>
                            </div>
                            <div className="form-group switch-enable selfView">
                                <div className="switch-mode">
                                    <input
                                        id="selfView"
                                        name="selfView"
                                        type="checkbox"
                                        onChange={this.handleChangeShowSelfView}
                                        checked={selfView}
                                    />
                                    <label htmlFor="selfView">
                                        Self view
                                    </label>
                                </div>
                            </div>
                            <div className="hint-text">
                                <p>If you are having problems, try restarting your browser.</p>
                                <p>Your preferences will automatically save.</p>
                            </div>
                            {this.renderCustomSettings()}
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

ConferenceSettings.propTypes = {
    isListener: PropTypes.bool.isRequired,
    attendeesSettingsOpened: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
    return {
        audioInputDevices: state.devices.audioInputDevices,
        audioInputDevice: state.devices.audioInputDevice,
        audioOutputDevices: state.devices.audioOutputDevices,
        audioOutputDevice: state.devices.audioOutputDevice,
        videoInputDevices: state.devices.videoInputDevices,
        videoInputDevice: state.devices.videoInputDevice,
        videoEnabled: state.devices.videoEnabled,
        audioEnabled: state.devices.audioEnabled,
        selfView: state.room.selfView,
        lowBandwidthMode: state.room.lowBandwidthMode
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setAudioInputDevice: (value) => {
            dispatch(devicesActions.setAudioInputDevice(value));
        },
        setAudioOutputDevice: (value) => {
            dispatch(devicesActions.setAudioOutputDevice(value));
        },
        setVideoInputDevice: (value) => {
            dispatch(devicesActions.setVideoInputDevice(value));
        },
        setVideoEnabled: (value) => {
            dispatch(devicesActions.setVideoEnabled(value));
        },
        setAudioEnabled: (value) => {
            dispatch(devicesActions.setAudioEnabled(value));
        },
        showSelfView: (value) => {
            dispatch(roomActions.showSelfView(value));
        },
        setLowBandwidthMode: (value) => {
            dispatch(roomActions.setLowBandwidthMode(value));
        }
    };
};

const ConferenceSettingsContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ConferenceSettings);

export default ConferenceSettingsContainer;